<template>
  <div>
    <div class="class_nav">
      <span>当前位置：</span> <span>作品管理</span> > <span>{{ title }}</span>
    </div>
    <div class="works-center">
      <div class="works-top dis-flex">
        <div class="works-img">
          <img :src="cover_img" alt="" />
        </div>
        <div class="works-title">
          <h3>{{ new_tatle }}</h3>

          <p>
            <span>{{ total_pic_num }}</span>
            <span style="color: #999999">&nbsp张照片</span>
            <span>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp</span>
            <span>{{ read_num }}</span>
            <span style="color: #999999">&nbsp次相册浏览</span>
          </p>
        </div>
      </div>
      <div class="works-list">
        <div class="production-by dis-flex">
          <div
            class="production-by-item cursor-pointer"
            @click="changetab(n, index)"
            :class="
              isactive === index
                ? 'production-by-item-ac'
                : 'production-by-item'
            "
            v-for="(n, index) in list"
            :key="index"
          >
            {{ n.day }}
          </div>
        </div>

        <ul class="curriculum dis-flex">
          <li class="photo-item" v-for="(p, index) in photoDtata" :key="index">
            <img :src="p.pic" alt="" />
            <span @click="delpic(p,index)">删除</span>
          </li>
        </ul>
      </div>
    </div>
    <div @click="toworks" class="bottom cursor-pointer">
      <div
        class="el-icon-plus"
        style="font-size: 30px; color: #4b85ff; margin-top: 30px"
      ></div>
      <p>上传</p>
    </div>
  </div>
</template>

<script>
import api from "@/service/api/api";

export default {
  name: "jobdetail",
  data() {
    return {
      title: "",
      isactive: 0,
      list: [],
      init_data: [],
      new_tatle: "",
      student_name: "",
      teacher_name: "",
      cover_img: "",
      read_num: "",
      total_pic_num: "",
      photoDtata: [],
      newphotoDtata: [],
      album_id:''
    };
  },
  created() {
    this.title = this.$route.query.title;
    this.album_id = this.$route.query.album_id;

    this.zydetail();
  },
  methods: {
    // 初始化
    zydetail() {
      let params = {
        album_id: this.album_id,
      };
      api.photdetail(params).then((res) => {
        if (res.errcode === 0) {
          this.cover_img = res.data.album[0].cover_img;
          this.total_pic_num = res.data.album[0].total_pic_num;
          this.read_num = res.data.album[0].read_num;
          this.new_tatle = res.data.album[0].title;
          this.list = res.data.daypic;
          this.photoDtata = res.data.allpic;
          this.newphotoDtata = res.data.allpic;
          this.list.unshift({
            day: "全部",
            pic:res.data.allpic
          });
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning",
          });
        }
      });
    },
    delpic(item,index){
       api.delphoto({id:item.id}).then((res) => {
        if (res.errcode === 0) {
          this.zydetail()
          this.$message({
             message: '删除成功',
            duration: 2000,
            type: "success",
          })
        } else {
          this.$message({
            message: res.errmsg,
            duration: 2000,
            type: "warning",
          });
        }
      });
    },
    // tab选项
    changetab(n, index) {
      // this.production = []
      this.isactive = index;
      console.log(n.day);
      this.list.forEach((item, index) => {
        console.log(item);
        if (n.day === item.day) {
          this.photoDtata = item.pic;
        }
      });
      // let student_name = this.list[index].student_name
    },
    // 上传跳转
    toworks() {
      this.$router.push({
        path: "/upphoto",
        query: {
          title: this.title,
          album_id:this.album_id
        },
      });
    },
  },
};
</script>

<style scoped>
.class_nav {
  width: 1200px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  color: #ff8e42;
}

.works-center {
  width: 1200px;
  margin: 0 auto;
  min-height: 867px;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.works-top {
  width: 100%;
  height: 224px;
}

.works-img {
  width: 500px;
  height: 224px;
}

.works-img > img {
  width: 100%;
  height: 100%;
}

.works-title {
  padding-top: 5px;
  padding-left: 10px;
}

.works-title > h3 {
  font-size: 22px;
  font-weight: 400;
  margin: 60px 0 20px 0;
  color: #000000;
}

.works-title p {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

.works-list {
  width: 100%;
  min-height: 500px;
  margin-top: 30px;
}

.production-by {
  width: 750px;
  height: 50px;
  line-height: 50px;
}

.production-by-item {
  min-width: 50px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  margin: 0 20px;
  color: #666666;
}

.production-by-item-ac {
  min-width: 50px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  color: #ff8e42;
  font-weight: 400;
  margin: 0 20px;
  border-bottom: 2px solid #ff8e42;
}

/*作品*/
.production_img {
  width: 645px;
  height: 394px;
  margin: 20px 0 0 0;
}

.production_img > img {
  width: 100%;
  height: 100%;
}

.curriculum {
  width: 1000px;
  min-height: 394px;
  margin: 20px auto;
  flex-wrap: wrap;
}
.photo-item {
  width: 285px;
  height: 190px;
  margin: 10px 10px 0 0;
  position:relative;
}
.photo-item > img {
  width: 100%;
  height: 100%;
}
.photo-item > span {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 42px;
  text-align: center;
  height: 28px;
  line-height: 28px;
  color: #fff;
  background: rgba(0,0,0, 0.4);
  border-radius: 30px;
}

.bottom {
  width: 110px;
  height: 110px;
  text-align: center;
  font-size: 30px;
  color: #4b85ff;
  border-radius: 50%;
  position: fixed;
  bottom: 100px;
  right: 70px;
  background: #ff8e42;
}
</style>